



































import { Component, Vue } from 'vue-property-decorator'
import { Account } from '@/api'

@Component({
  components: {
    Logo: () => import('@/components/Logo.vue'),
    FAQ: () => import('@/components/FAQ.vue')
  }
})
export default class DownloadLauncher extends Vue {
  version = ''
  x64: DownloadLink = {}
  x86: DownloadLink = {}

  async mounted() {
    const response = await Account.getLauncherInfos()

    const launcherInfos = response.result;

    this.x64 = launcherInfos.find(e => e.architecture == 'x64')
    this.x86 = launcherInfos.find(e => e.architecture == 'x86')
    this.version = launcherInfos[0]?.version
  }

  download(architectureType: 'x64' | 'x86') {
    window.open(architectureType === 'x64' ? this.x64?.url : this.x86?.url)
  }
}
